import React from 'react'
import { StarIcon } from '@heroicons/react/solid'

function Stats() {
  return (
    <div id='stats' className='bg-gray-50 pt-12 sm:pt-16'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='max-w-4xl mx-auto text-center'>
          <h2 className='text-3xl font-extrabold text-gray-900 sm:text-4xl'>
            Leave your home in trusted hands.
          </h2>
          <p className='mt-3 text-xl text-gray-500 sm:mt-4'>
            We let our numbers and years of experience speak for themselves.
          </p>
        </div>
      </div>
      <div className='mt-10 pb-12 bg-white sm:pb-16'>
        <div className='relative'>
          <div className='absolute inset-0 h-1/2 bg-gray-50' />
          <div className='relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8'>
            <div className='max-w-4xl mx-auto'>
              <dl className='rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3'>
                <div className='flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r'>
                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500'>of Happy Customers</dt>
                  <dd className='order-1 text-5xl font-extrabold text-yale'>1000's</dd>
                </div>
                <div className='flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r'>
                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500'>on Yelp and Google</dt>
                  <dd className='order-1 flex justify-center text-5xl font-extrabold text-yale'>
                    <div className='flex-shrink-0 flex'>
                      <StarIcon className='h-12 w-8 text-yellow-400' aria-hidden='true' />
                      <StarIcon className='h-12 w-8 text-yellow-400' aria-hidden='true' />
                      <StarIcon className='h-12 w-8 text-yellow-400' aria-hidden='true' />
                      <StarIcon className='h-12 w-8 text-yellow-400' aria-hidden='true' />
                      <StarIcon className='h-12 w-8 text-yellow-400' aria-hidden='true' />
                    </div>
                  </dd>
                </div>
                <div className='flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l'>
                  <dt className='order-2 mt-2 text-lg leading-6 font-medium text-gray-500'>Years in Business</dt>
                  <dd className='order-1 text-5xl font-extrabold text-yale'>15+</dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Stats
